<template>
  <v-dialog
    v-model="dialog"
    width="800"
    scrollable
  >
    <v-card>
      <v-card-title>
        {{ titulo }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <div class="text-h6 ml-5 mt-2">
          Archivos existentes
        </div>
        <!-- lista de archivos -->
        <v-data-table
          class="elevation-2 mt-2 mx-4"
          :headers="headers"
          :items="archivos"
          :loading="load"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.nombre`]="{ item }">
            <v-icon
              v-if="tiposArchivos.find(a => a.extension == item.extension)"
              :color="tiposArchivos.find(a => a.extension == item.extension).color"
              style="margin-bottom: 3px"
              small
              left
            >
              {{ tiposArchivos.find(a => a.extension == item.extension).icono }}
            </v-icon>
            {{ item.nombre }}
          </template>
          <template v-slot:[`item.fecha`]="{ value }">
            {{ moment(value).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="success"
              title="Descargar"
              class="mr-2"
              small
              @click="descargar(item)"
            >
              fas fa-download
            </v-icon>
            <v-icon
              color="info"
              title="Ver"
              class="mr-2"
              small
              @click="ver(item)"
            >
              fas fa-eye
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="500"
              type="warning"
              border="left"
              dense
              text
            >
              No se econtraron archivos para el comprobante
            </v-alert>
          </template>
        </v-data-table>
        <!-- subir archivos -->
        <FileDropzone
          :key="refrescar"
          :tipos="['.heic', '.heif', '.jpg', '.png', '.pdf', '.webp']"
          :max_size="25"
          :nombre="`${comp_id}`"
          :ruta="`/cajas/${caja_num}`"
          :raiz="0"
          :tabla="8"
          :id="comp_id"
          multiple
          alerta
          accion
          @action="agregar_archivo"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="ml-3 mt-2"
          color="error"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- componentes -->
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="nombre"
      :titulo="nombre"
    />
    <ImgViewer
      v-model="dialog_img"
      :img="img"
      :nombre="nombre"
    />
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { tipos_archivos } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FileDropzone from '../util/FileDropzone.vue'
import PDFViewer from '../../util/plantillas/PDFViewer.vue'
import ImgViewer from '../util/ImgViewer.vue'

export default {
  data () {
    return {
      moment: moment,
      tiposArchivos: tipos_archivos,
      refrescar: 0,
      load: false,
      dialog_pdf: false,
      dialog_img: false,
      nombre: '',
      pdf: '',
      img: '',
      archivos: [],
      headers: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Usuario', value: 'usuario' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false, align: 'center' },
      ]
    }
  },
  props: {
    value: Boolean,
    titulo: String,
    comp_id: Number,
    caja_num: Number
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FileDropzone,
    PDFViewer,
    ImgViewer
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load = true
        let resultado = await this.$store.dispatch('caja/get_files', this.comp_id)
        this.load = false

        if (resultado.exito == 1) {
          this.archivos = resultado.data
        } else {
          this.dialog = false
          this.$swal.fire({
            icon: 'error',
            title: resultado.message
          })
        }

      } else {
        this.archivos = []
        this.refrescar ++
      }
    }
  },
  methods: {
    agregar_archivo (file) {
      this.$emit('archivoControl', {
        file: file.file,
        name: file.name,
        tipo: file.type.toUpperCase()
      })
      this.archivos.push({
        file: file.file,
        nombre: file.name,
        extension: file.type,
        usuario: this.$store.state.username,
        fecha: new Date()
      })
    },
    descargar (item) {
      const routeData = this.$router.resolve({ path: `/descargarArchivo?path=/cajas/${this.caja_num}/${item.nombre}&raiz=0` })
      window.open(routeData.href, '_blank')
    },
    ver (item) {
      this.nombre = `${item.nombre}`
      const tipo = tipos_archivos.find(a => a.extension == item.extension).tipo
      if (tipo == 'PDF') {
        this.pdf = `data:application/pdf;base64,${item.file}`
        this.dialog_pdf = true
      }
      else if (tipo == 'IMG') {
        this.img = `data:${tipos_archivos.find(a => a.extension == item.extension).mime};base64,${item.file}`
        this.dialog_img = true
      }
    }
  }
}
</script>